import { useEffect } from 'react'
import { navigate } from 'gatsby-plugin-intl'

const IndexPage = () => {
  useEffect(() => {
    if (global.window) {
      navigate('/')
    }
  }, [])

  return null
}

export default IndexPage
